import React, { useState } from 'react';
import { func, string } from 'prop-types';
// import { BiArrowBack } from 'react-icons/bi';
import {
  Box,
  Heading,
  FormControl,
  Input,
  Checkbox,
  FormLabel,
  Textarea,
  Button,
  useToast,
  MenuButton,
  Menu,
  InputGroup,
  Icon,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { BiChevronDown } from 'react-icons/bi';

import useFormFields from '@/hooks/useFormFields';
import useGiftConfirmation from '@/usecase/use-gift-confirmation';
import { useGuest } from '@/context/guest';
import useInvitation from '@hooks/useInvitation';

import { ENABLE_SEND_GIFT, ENABLE_BANK_TRANSFER } from '@/constants/feature-flags';
import { COUNTRY_CODES } from '@constants/country-codes';
import txtWording from './locales';
import {
  HEADING_STYLES,
  TEXT_DECORATION,
  TEXT_PRIMARY,
  BG_SECONDARY,
  DEFAULT_BUTTON_PROPS,
} from '@constants/colors';

import { INPUT_COMMON_PROPS, FORM_LABEL_PROPS, SELECT_COMMON_PROPS } from './types';

function Confirmation({ lang }) {
  const { onPostConfirmation, loading } = useGiftConfirmation();
  const { guest, isRegistered } = useGuest();
  const { name, phone_number, country_code } = guest;
  const isInvitation = useInvitation();

  const [isGiftCard, setIsGiftCard] = useState(false);
  const [isBankTransfer, setIsBankTransfer] = useState(false);
  const toast = useToast();

  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: isRegistered ? name : isInvitation ? '' : name,
    isGift: false,
    note: '',
    phone_number: phone_number,
  });

  const [selectedCountryCode, setSelectedCountryCode] = useState(country_code || '62');

  const handleCountryCodeChange = (code) => {
    setSelectedCountryCode(code);
  };

  /**
   * @function handleSendConfirmation
   * @description a function handler for submit confirmation
   */
  const handleSendConfirmation = async () => {
    if (!formFields.name) {
      toast({
        title: 'Oops!',
        description: txtWording.fillNameFirst[lang],
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    await onPostConfirmation({
      name: formFields.name,
      country_code: selectedCountryCode,
      phone_number: formFields.phone_number,
      notes: formFields.note,
      isBankTransfer: String(isBankTransfer).toUpperCase(),
      isGiftCard: String(isGiftCard).toUpperCase(),
      onSuccess: () => {
        onResetForm();
        toast({
          title: txtWording.submitted[lang],
          description: txtWording.successSent[lang],
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Oops!',
          description: txtWording.failedSent[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <Box>
      <Box bgColor="bgPrimary">
        {/* Heading & Desc Section */}

        <Box bgColor={BG_SECONDARY} borderTop={'1px solid black'} borderBottom={'1px solid black'}>
          <Heading {...HEADING_STYLES} color="bgPrimary" fontSize={'2xl'} padding={'1rem 2rem'}>
            {txtWording.title[lang]}
          </Heading>
        </Box>

        <Box padding={'1.5rem 2rem 0 2rem'}>
          <FormControl margin="16px 0">
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder={txtWording.name[lang]}
              value={formFields.name}
              onChange={createChangeHandler('name')}
            />
          </FormControl>
          <FormControl margin="16px 0">
            <InputGroup size="sm">
              <Menu>
                <MenuButton
                  {...SELECT_COMMON_PROPS}
                  as={Button}
                  rightIcon={<Icon as={BiChevronDown} />}
                >
                  {`+${selectedCountryCode}`}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="scroll">
                  {COUNTRY_CODES.map((country) => (
                    <MenuItem
                      key={country.dial_code}
                      onClick={() => handleCountryCodeChange(country.dial_code)}
                    >
                      {`${country.name}: +${country.dial_code}`}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Input
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                type="number"
                value={formFields.phone_number}
                onChange={createChangeHandler('phone_number')}
              />
            </InputGroup>
          </FormControl>
          <FormControl margin="24px 0" fontFamily="serif">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.giftType[lang]}:</FormLabel>
            {ENABLE_BANK_TRANSFER && (
              <Box>
                <Checkbox
                  borderColor="bgSecondary"
                  value={isBankTransfer}
                  onChange={() => setIsBankTransfer(!isBankTransfer)}
                >
                  {txtWording.bank[lang]}
                </Checkbox>
              </Box>
            )}
            {ENABLE_SEND_GIFT && (
              <Box>
                <Checkbox borderColor="bgSecondary" value={isGiftCard} onChange={() => setIsGiftCard(!isGiftCard)}>
                  {txtWording.gift[lang]}
                </Checkbox>
              </Box>
            )}
          </FormControl>
          <FormControl margin="16px 0">
            <Textarea
              {...INPUT_COMMON_PROPS}
              value={formFields.note}
              placeholder={txtWording.notes[lang]}
              onChange={createChangeHandler('note')}
            />
          </FormControl>
        </Box>
        <Box
          marginTop="24px"
          width={`calc(100% - 4rem)`}
          marginX={'auto'}
          display={'flex'}
          justifyContent={'end'}
        >
          <Button
            isLoading={loading}
            fontWeight="normal"
            size="sm"
            fontStyle="italic"
            onClick={handleSendConfirmation}
            {...DEFAULT_BUTTON_PROPS}
            color="bgPrimary"
            bgColor="bgSecondary"
            fontSize={'18px'}
            padding="16px 20px"
            _hover={{
              backgroundColor: TEXT_DECORATION,
              borderColor: TEXT_PRIMARY,
              transform: 'none',
            }}
          >
            {txtWording.sendConfirm[lang]}
          </Button>
        </Box>
      </Box>
      {/* <Box paddingTop="24px">
        <Center>
          <Button
            leftIcon={<BiArrowBack />}
            type="button"
            size="sm"
            fontWeight="normal"
            fontStyle="italic"
            fontSize="md"
            bgColor="#bgSecondary"
            colorScheme="blackAlpha"
            color="white"
            onClick={onBack}
          >
            {txtWording.backToHome[lang]}
          </Button>
        </Center>
      </Box> */}
    </Box>
  );
}

Confirmation.propTypes = {
  lang: string,
  onBack: func.isRequired,
};

Confirmation.defaultProps = {
  lang: 'id',
};

export default Confirmation;
